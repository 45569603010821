@import "../../variables";
@import "~bootstrap/scss/mixins/breakpoints";
//@import "~base/experience/components/commerceAssets/productTile";

@import "~schaffrath/components/productTiles";

// DO NOT display quickview for now since we have not yet implemented the feature.
// --> Salesforce comment in src/sfra_framework/cartridges/app_storefront_base/cartridge/client/default/scss/experience/components/commerceAssets/productTile.scss
.storepage .product-tile .quickview {
	display: none;
}
